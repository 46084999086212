import React, { memo, PropsWithChildren } from "react";
import { Avatar, Card, Divider, Table, TableProps, Tag, theme } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { TEmployee } from "../../utils/types/employee.types";
import dayjs from "dayjs";
import { API_BASE_URL } from "../../utils/const";
import {
  AdaptReactionColor,
  AdaptReactionRu,
  EmployeeStatusColor,
} from "../../utils/enums";

import EditEmployeeModalBtnElement from "./modal-buttons/edit-employee-modal-btn-element";
import DeleteEmployeeModalBtnElement from "./modal-buttons/delete-employee-modal-btn-element";

type EmplTableProps = {
  children?: JSX.Element;
  emplList?: TEmployee[];
};

export const EmplTableElement = ({
  emplList = [],
  children,
}: PropsWithChildren<EmplTableProps>) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const columns: TableProps<TEmployee>["columns"] = [
    {
      title: "",
      dataIndex: "avatar_url",
      key: "avatarImg",
      render(v, r) {
        const url = `${API_BASE_URL}/static/av/${r.id}.png`;

        if (v) {
          return <Avatar size="default" src={url} />;
        }

        return <Avatar icon={<UserOutlined />} />;
      },
    },
    {
      title: "ФИО",
      dataIndex: "full_name",
      key: "fullName",
      sorter: {
        compare: (a, b) => a.full_name.localeCompare(b.full_name),
      },
    },
    {
      title: "Куратор",
      dataIndex: "manager",
      key: "managerName",
      render(v, r) {
        return `${r.manager.full_name} (${r.manager.email})`;
      },
      sorter: {
        compare: (a, b) =>
          a.manager.full_name.localeCompare(b.manager.full_name),
      },
    },
    {
      title: "Старт адаптации",
      dataIndex: "adaptationStartDate",
      key: "adaptationStartDate",
      render(v) {
        return dayjs(v).format("DD.MM.YYYY");
      },
      sorter: {
        compare: (a, b) =>
          dayjs(a.adaptationStartDate).isAfter(dayjs(b.adaptationStartDate))
            ? 1
            : 0,
      },
    },
    {
      title: "Последнее действие",
      dataIndex: "updatedAt",
      key: "emplUpdatedAt",
      render(v) {
        return dayjs(v).format("DD.MM.YYYY");
      },
      sorter: {
        compare: (a, b) =>
          dayjs(a.adaptationStartDate).isAfter(dayjs(b.adaptationStartDate))
            ? 1
            : 0,
      },
    },
    {
      title: "День обновлен",
      dataIndex: "dayUpdatedAt",
      key: "dayUpdatedAt",
      render(v) {
        return dayjs(v).format("DD.MM.YYYY");
      },
      sorter: {
        compare: (a, b) =>
          dayjs(a.adaptationStartDate).isAfter(dayjs(b.adaptationStartDate))
            ? 1
            : 0,
      },
    },
    {
      title: "Конец испыталки",
      dataIndex: "probationEndDate",
      key: "probationEndDate",
      render(v) {
        return dayjs(v).isAfter(dayjs()) ? (
          <Tag color="yellow">{dayjs(v).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="green">{dayjs(v).format("DD.MM.YYYY")}</Tag>
        );
      },
      sorter: {
        compare: (a, b) =>
          dayjs(a.probationEndDate).isAfter(dayjs(b.probationEndDate)) ? 1 : 0,
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "emplStatus",
      render(v, r) {
        return <Tag color={EmployeeStatusColor[r.status]}>{r.status}</Tag>;
      },
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      title: "День",
      dataIndex: "day",
      key: "emplDay",
      render(v) {
        return v === -1 ? (
          <Tag color="blue">—</Tag>
        ) : (
          <Tag color="green">{v}</Tag>
        );
      },
      sorter: {
        compare: (a, b) => a.day - b.day,
      },
    },
    {
      title: "Капсула",
      dataIndex: "cap",
      key: "emplCap",
      render(v, r) {
        return r.day === -1 ? (
          <Tag color="blue">—</Tag>
        ) : (
          <Tag color="green">{v}</Tag>
        );
      },
      sorter: {
        compare: (a, b) => a.cap - b.cap,
      },
    },
    {
      title: "Реакция",
      dataIndex: "adaptReaction",
      key: "emplReaction",
      render(_, r) {
        if (r.adaptReaction) {
          return (
            <Tag color={AdaptReactionColor[r.adaptReaction]}>
              {AdaptReactionRu[r.adaptReaction]}
            </Tag>
          );
        }

        return <Tag color={"cyan"}>Нет</Tag>;
      },
      sorter: {
        compare: (a, b) =>
          a.adaptReaction?.localeCompare(b.adaptReaction ?? "") ?? 0,
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      render(_, empl) {
        return (
          <>
            <EditEmployeeModalBtnElement employee={empl} />
            <DeleteEmployeeModalBtnElement employee={empl} />
          </>
        );
      },
    },
  ];

  return (
    <Card
      style={{
        background: colorBgContainer,
        minHeight: 280,
        paddingInline: 12,
        borderRadius: borderRadiusLG,
      }}
    >
      {children}
      <Divider type="horizontal" style={{ maxHeight: "1vh" }} />
      <Table<TEmployee>
        columns={columns}
        dataSource={emplList}
        rowKey={(obj) => obj.email}
      />
    </Card>
  );
};

export default memo(EmplTableElement);
