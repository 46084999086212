import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";
import { UserProfile } from "fox-mattermost";
import { api } from "../../store/store";

export interface LoopUserSelectProps
  extends Omit<SelectProps, "options" | "children"> {
  valueProp?: "email" | "id";
  debounceTimeout?: number;
}

const LoopUserSelectElement = ({
  valueProp = "id",
  ...props
}: LoopUserSelectProps) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<SelectProps["options"]>(undefined);

  const loadOptions = async (name: string) => {
    setOptions([]);
    setFetching(true);

    return api
      .post<{ users: UserProfile[] }>(`/employee/autocomplete?name=${name}`)
      .then(({ data: { users } }) => {
        setOptions(
          users?.map(({ first_name, last_name, ...user }) => ({
            key: user.id,
            value: user[valueProp],
            label: `${first_name} ${last_name} (${user.email})`,
          }))
        );
        setFetching(false);
      });
  };

  useEffect(() => {
    if (!fetching && !options) {
      loadOptions("foxford");
    }
  }, [options, fetching]);

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={(v: string) => loadOptions(v || "foxford")}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      placeholder="Начни вводить имя, email или username"
      {...props}
      options={options}
    />
  );
};

export default LoopUserSelectElement;
