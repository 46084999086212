import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TAuthData,
  TYaAuthData,
  TYaAuthToken,
} from "../../../utils/types/auth.type";
import AppDispatch from "../../../utils/types/app-dispatch.types";
import { State } from "../../../utils/types/state.types";
import axios, { AxiosInstance } from "axios";
import { YA_CLIENT_ID, YA_CLIENT_SECRET } from "../../../utils/const";
import QueryString from "qs";
import { TUser } from "../../../utils/types/user.types";
import { serializeAxiosError } from "../../../utils/utils";

export const setAuthAction = createAsyncThunk<
  TAuthData,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("auth/setAuth", async (code, { rejectWithValue, extra: { api } }) => {
  try {
    /** Получаем токен от яндеха */
    const { data: tokenData } = await axios.post<TYaAuthToken>(
      `https://oauth.yandex.ru/token`,
      QueryString.stringify({
        grant_type: "authorization_code",
        client_id: YA_CLIENT_ID,
        client_secret: YA_CLIENT_SECRET,
        code,
      }),
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );

    /** Меняем токен на данные */
    const { data: yaData } = await axios.post<TYaAuthData>(
      `https://login.yandex.ru/info?&format=json`,
      {},
      {
        headers: { Authorization: `OAuth ${tokenData.access_token}` },
      }
    );

    /** Проверяем что юзер есть на беке */
    const {
      data: { email, role, id },
    } = await api.post<TUser>(`/user/auth`, {
      email: yaData.default_email,
    });

    /** Если все ок отдаем данные */
    return { ...yaData, email, role, loopId: id };
  } catch (err) {
    return rejectWithValue(serializeAxiosError(err, "Ошибка авторизации"));
  }
});
