import React, { memo, useState } from "react";
import {
  Button,
  Modal,
} from "antd";
import { TUser } from "../../../utils/types/user.types";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { deleteUserAction } from "../../../store/slices/user/user.actions";

const DeleteUserModalBtnElement = ({ user }: { user: TUser }) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onDeleteModalOk = (email: string) => {
    dispatch(deleteUserAction({ email }));
    setIsDeleteModalOpen(false);
  };

  const onDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      <Button
        variant="filled"
        color="danger"
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Удалить
      </Button>
      <Modal
        title="Подтверждение"
        open={isDeleteModalOpen}
        onOk={() => onDeleteModalOk(user.email)}
        onCancel={onDeleteModalCancel}
        okText="Удалить"
        cancelText="Не надо"
        okButtonProps={{ variant: "solid", color: "danger" }}
      >
        Точно удалить пользователя?
      </Modal>
    </>
  );
};

export default memo(DeleteUserModalBtnElement);
