import { createSlice } from "@reduxjs/toolkit";
import { ReducerName } from "../../../utils/enums";
import { TUserState } from "../../../utils/types/state.types";
import {
  addUserAction,
  deleteUserAction,
  fetchUsersAction,
} from "./user.actions";

const initialState: TUserState = {
  users: undefined,
  isLoading: false,
  error: null,
};

export const userSlice = createSlice({
  name: ReducerName.User,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersAction.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchUsersAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(addUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUserAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});
