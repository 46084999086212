import React, { memo } from "react";
import { Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import { NavLink } from "react-router-dom";
import Icon, {
  FileTextOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { logOut } from "../../store/slices/auth/auth.slice";
import { useUserData } from "../../hooks/user-user-data/use-user-data";
import { Role } from "../../utils/enums";

const AppHeaderElement = () => {
  const dispatch = useAppDispatch();

  const { role, status } = useUserData();

  const hasUsers = role === Role.Admin || role === Role.SuperAdmin;

  const items = [
    {
      key: "mainPage",
      label: "Главная",
      style: {
        fontWeight: "bold",
      },
      extra: (
        <NavLink
          to={`/`}
          className={({ isActive }) => (isActive ? "ant-menu-item-active" : "")}
        >
          <Icon
            component={() => (
              <img
                alt="Лого"
                width="20"
                src={`logo-navbar.png`}
                style={{ fontSize: "16px" }}
              />
            )}
          />
        </NavLink>
      ),
    },
    {
      key: "employees",
      label: "Сотрудники",
      style: {
        fontWeight: "bold",
      },
      extra: (
        <NavLink
          to={`/employees`}
          className={({ isActive }) => (isActive ? "ant-menu-item-active" : "")}
        >
          <TeamOutlined />
        </NavLink>
      ),
    },
    {
      key: "caps",
      label: "Капсулы",
      style: {
        fontWeight: "bold",
      },
      extra: (
        <NavLink
          to={`/caps`}
          className={({ isActive }) => (isActive ? "ant-menu-item-active" : "")}
        >
          <FileTextOutlined />
        </NavLink>
      ),
    },
    hasUsers
      ? {
          key: "usersPage",
          label: "Пользователи",
          style: {
            fontWeight: "bold",
          },
          extra: (
            <NavLink
              to={`/users`}
              className={({ isActive }) =>
                isActive ? "ant-menu-item-active" : ""
              }
            >
              <TeamOutlined />
            </NavLink>
          ),
        }
      : null,
    {
      key: "logout",
      label: "Выйти",
      style: {
        fontWeight: "bold",
      },
      onClick: () => {
        dispatch(logOut());
      },
    },
  ];

  return (
    <Header style={{ display: "flex", alignItems: "center" }}>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["main"]}
        items={status === "auth" ? items : []}
        style={{ flex: 1, minWidth: 0 }}
      />
    </Header>
  );
};

export default memo(AppHeaderElement);
