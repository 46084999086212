import { createSelector } from "@reduxjs/toolkit";
import { State } from "../../../utils/types/state.types";

export const getUsersState = (state: State) => state.USER;

export const getUsers = createSelector(
  getUsersState,
  (state) => state.users
);

export const getUsersLoading = createSelector(
  getUsersState,
  (state) => state.isLoading
);

export const getUsersError = createSelector(
  getUsersState,
  (state) => state.error
);
