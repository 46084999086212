import { useNavigate, useSearchParams } from "react-router-dom";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { setAuthAction } from "../../store/slices/auth/auth.actions";
import React, { useEffect } from "react";
import useAppSelector from "../../hooks/use-app-selector/use-app-selector";
import {
  getAuthIsLoading,
  getAuthStatus,
} from "../../store/slices/auth/auth.selectors";
import { Spin } from "antd";

/** Страница для yandex oauth */
export const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const status = useAppSelector(getAuthStatus);
  const isLoading = useAppSelector(getAuthIsLoading);

  const code = searchParams.get("code");

  useEffect(() => {
    if (code && status === "noAuth") {
      dispatch(setAuthAction(code));
    }
  }, [status, navigate, dispatch, code]);

  return <Spin spinning={isLoading} />;
};
