import React, { memo } from "react";
import Icon from "@ant-design/icons";
import { Button, Card, Result } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import useAppSelector from "../../hooks/use-app-selector/use-app-selector";
import {
  getAuthData,
  getAuthError,
  getAuthStatus,
} from "../../store/slices/auth/auth.selectors";
import { YA_CLIENT_ID } from "../../utils/const";
import { ReactComponent as yaIcon } from "../../ya.svg";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { logOut, resetAuthError } from "../../store/slices/auth/auth.slice";

const MainPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector(getAuthStatus);
  const authData = useAppSelector(getAuthData);
  const error = useAppSelector(getAuthError);

  const cardStyle: React.CSSProperties = {
    marginTop: "5vh",
    marginLeft: "30vw",
    width: "33vw",
    alignItems: "center",
  };

  const isAuthError = error?.code === "401" || error?.code === "403";

  if (error) {
    return (
      <Card style={cardStyle}>
        <Result
          status={403}
          title={
            isAuthError
              ? `У тебя нет доступа к этому сервису.`
              : `Произошла ошибка`
          }
          subTitle={
            isAuthError
              ? `Обратись в поддержку. Ошибка: ${error.message}`
              : `Если он должен быть - обратись в поддержку. Ошибка: ${error.message}`
          }
          extra={
            <Button
              color="primary"
              variant="filled"
              onClick={() => {
                dispatch(logOut());
                dispatch(resetAuthError());
                navigate(`/`);
              }}
            >
              Выйти
            </Button>
          }
        />
      </Card>
    );
  }

  switch (status) {
    case "noAuth":
      return (
        <Card style={cardStyle}>
          <Result
            icon={
              <Icon
                component={() => <img alt='Фоксик-пушкин' height="200" src={`logo-hat.png`} />}
              />
            }
            title={`Привет!`}
            subTitle={`Войди с помощью кнопки ниже`}
            extra={
              <Button
                type="default"
                size="large"
                icon={
                  <Icon style={{ fontSize: "24px" }} component={yaIcon}></Icon>
                }
                href={`https://oauth.yandex.ru/authorize?response_type=code&client_id=${YA_CLIENT_ID}`}
                style={{
                  height: 50,
                  marginTop: "2vh",
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "black",
                  color: "whitesmoke",
                }}
              >
                Войти с помощью Яндекс ID
              </Button>
            }
          />
        </Card>
      );
    case "auth": {
      return (
        <Card style={cardStyle}>
          <Result
            icon={
              <Icon
                component={() => (
                  <img alt="Фоксик крутой" height="200" src={`logo-cool.png`} />
                )}
              />
            }
            title={`Привет, ${authData?.real_name}!`}
            subTitle={'Добро пожаловать в админку HR-Бота "Мистер Фокс"'}
            extra={
              <Button
                color="primary"
                variant="filled"
                onClick={() => {
                  return navigate("/employees");
                }}
                style={{
                  marginTop: "2vh",
                  height: 50,
                  fontSize: "16px",
                }}
              >
                <NavLink to={`/employees`}>
                  Перейти к списку сотрудников
                </NavLink>
              </Button>
            }
          />
        </Card>
      );
    }
    default:
      return (
        <Card style={cardStyle}>
          <Result
            status="403"
            title="403"
            subTitle="У тебя нет доступа к этому сервису. Если он должен быть - обратись в поддержку"
          />
        </Card>
      );
  }
};

export default memo(MainPage);
