import { useEffect } from "react";
import {
  getAuthData,
  getAuthError,
  getAuthIsLoading,
  getAuthLoopId,
  getAuthRole,
  getAuthStatus,
} from "../../store/slices/auth/auth.selectors";
import useAppSelector from "../use-app-selector/use-app-selector";
import useAppDispatch from "../use-app-dispatch/use-app-dispatch";
import { logOut, setAuth } from "../../store/slices/auth/auth.slice";
import { useNavigate } from "react-router-dom";

export const useUserData = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const managerId = useAppSelector(getAuthLoopId);
  const status = useAppSelector(getAuthStatus);
  const role = useAppSelector(getAuthRole);
  const authData = useAppSelector(getAuthData);
  const error = useAppSelector(getAuthError);
  const isLoading = useAppSelector(getAuthIsLoading);

  const authString = localStorage.getItem("user");

  useEffect(() => {
    if (authString) {
      dispatch(setAuth(authString));
    }

    if (!managerId) {
      dispatch(logOut())
    }
  }, [status, authString, navigate, dispatch]);

  return { managerId, status, role, authData, error, isLoading, authString };
};
