export const API_BASE_URL = `${
  (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "") +
  (process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : "")
}`;

export const YA_GET_TOKEN_URL = `https://oauth.yandex.ru/token`;

export const YA_CLIENT_ID = process.env.REACT_APP_YA_CLIENT_ID;
export const YA_CLIENT_SECRET = process.env.REACT_APP_YA_CLIENT_SECRET;

export const EMAIL_REGEX = /[A-z.\d]*@foxford\.(ru|pro)/;
