import { createSlice } from "@reduxjs/toolkit";
import { ReducerName } from "../../../utils/enums";
import { TCapState } from "../../../utils/types/state.types";
import {
  fetchCapsAction,
  fetchActionUrlAction,
  testCapAction,
  updateCapAction,
} from "./cap.actions";

const capInitialState: TCapState = {
  caps: undefined,
  isLoading: false,
  error: null,
};

export const capSlice = createSlice({
  name: ReducerName.Cap,
  initialState: capInitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCapsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCapsAction.fulfilled, (state, action) => {
        state.caps = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCapsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updateCapAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCapAction.fulfilled, (state, action) => {
        state.isLoading = false;

        const index = state.caps?.findIndex(
          ({ name }) => name === action.payload.name
        );

        if (index && state.caps?.[index]) {
          state.caps[index] = action.payload;
        }
      })
      .addCase(updateCapAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchActionUrlAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActionUrlAction.fulfilled, (state, { payload }) => {
        state.actionUrl = payload;

        state.isLoading = false;
      })
      .addCase(fetchActionUrlAction.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.error;
      })
      .addCase(testCapAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(testCapAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(testCapAction.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.error;
      });
  },
});
