import React, { memo } from "react";
import { Button, Card, Divider, Spin, Table, Tag, theme } from "antd";
import { TCap } from "../../utils/types/cap.types";
import useCaps from "../../hooks/use-caps/use-caps";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { testCapAction } from "../../store/slices/cap/cap.actions";
import { useUserData } from "../../hooks/user-user-data/use-user-data";
import CapEditorModalBtnElement from "../elements/modal-buttons/cap-editor-modal-btn-element";
import { LoopStyleAntColor } from "../../utils/enums";
import EditActionUrlBtnElement from "../elements/modal-buttons/edit-action-url-btn-element";

/** Просмотр и редактирование капсул */
const CapsPage = () => {
  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { caps, capsLoading } = useCaps();
  const { authData } = useUserData();

  const onTestCapClick = (capName: string) =>
    dispatch(
      testCapAction({
        capName,
        email: authData?.default_email ?? "",
      })
    );

  return (
    <Spin spinning={capsLoading}>
      <Card
        style={{
          background: colorBgContainer,
          minHeight: 280,
          borderRadius: borderRadiusLG,
          marginTop: "3vh",
        }}
      >
        <EditActionUrlBtnElement />
        <Table<TCap>
          dataSource={caps}
          scroll={{ scrollToFirstRowOnChange: true }}
          rowKey={(obj) => obj.name}
          columns={[
            {
              title: "День",
              dataIndex: "day",
              key: "dayIndex",
              render(_, r) {
                return r.day?.index ?? r.bonusDay?.index ?? "Нет";
              },
              defaultSortOrder: "ascend",
              sorter: {
                compare: (a, b) => {
                  if (!a.day && !a.bonusDay) {
                    return -1;
                  }

                  return (
                    (a.day?.index || a.bonusDay?.index || 0) -
                    (b.day?.index || b.bonusDay?.index || 0)
                  );
                },
                multiple: 2,
              },
            },
            {
              title: "Порядок",
              dataIndex: "index",
              key: "capIndex",
              defaultSortOrder: "ascend",
              render(_, r) {
                return r.bonusDay ? "Бонус" : r.day ? r.index : "Нет";
              },
              sorter: {
                multiple: 1,
                compare: (a, b) => {
                  if (!a.day && !a.bonusDay) {
                    return -1;
                  }

                  return (a.index || 0) - (b.index || 0);
                },
              },
            },
            {
              title: "Имя",
              dataIndex: "label",
              key: "capLabel",
              sorter: {
                compare: (a, b) => a.label.localeCompare(b.label),
              },
            },
            {
              title: "Кнопки",
              dataIndex: "title",
              key: "post",
              render(_, r) {
                const actions = r.post?.props?.attachments?.[0].actions;
                return actions?.length && actions.length > 0
                  ? actions.map(({ name, id, style }) => (
                      <Tag key={id} color={LoopStyleAntColor[style]}>
                        {name}
                      </Tag>
                    ))
                  : "Нет";
              },
            },
            {
              title: "",
              dataIndex: "",
              key: "editButton",
              render(_, r) {
                return (
                  <>
                    <CapEditorModalBtnElement cap={r} />
                    <Divider type="vertical" />
                    <Button
                      onClick={() => onTestCapClick(r.name)}
                      variant="filled"
                      style={{
                        background:
                          "linear-gradient(to bottom right, rgba(102,176,255,1) 0%, rgba(134,0,255,1) 100%)",
                        color: "white",
                        border: "0px solid rgba(0, 0, 0, .5)",
                      }}
                    >
                      Отправить мне
                    </Button>
                  </>
                );
              },
            },
          ]}
        />
      </Card>
    </Spin>
  );
};

export default memo(CapsPage);
