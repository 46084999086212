import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../utils/const";

export const createApi = () => {
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_LOOP_TOKEN}`,
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response) {
        const { data } = error.response;
        console.log({ data });

        let msg = "Ошибка";

        if (data && typeof data === "object") {
          if ("error" in data) {
            msg = typeof error === "object" ? (data.error as string) : error;
          }

          if ("message" in data) {
            msg = String(data.message);
          }
        }

        toast.error(msg);
      }

      throw error;
    }
  );

  return api;
};
