import { Middleware } from "redux";
import { rootReducer } from "../store/store";
import browserHistory from "../browser-history";

type Reducer = ReturnType<typeof rootReducer>;

export const redirect: Middleware<unknown, Reducer> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_store) => (next) => (action: unknown) => {
    if (
      action &&
      typeof action === "object" &&
      "type" in action &&
      "payload" in action &&
      action.type === "app/redirectToRoute"
    ) {
      browserHistory.push(action.payload as string);
    }

    return next(action);
  };
