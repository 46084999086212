import { combineReducers } from "@reduxjs/toolkit/react";
import { configureStore } from "@reduxjs/toolkit";
import { ReducerName } from "../utils/enums";
import { createApi } from "../services/api";
import { redirect } from "../middlewares/redirect";
import { employeeSlice } from "./slices/employee/employee.slice";
import { createLoopApi } from "../services/loop-api";
import { authSlice } from "./slices/auth/auth.slice";
import { capSlice } from "./slices/cap/cap.slice";
import { userSlice } from "./slices/user/user.slice";

export const rootReducer = combineReducers({
  [ReducerName.Auth]: authSlice.reducer,
  [ReducerName.Cap]: capSlice.reducer,
  [ReducerName.Employee]: employeeSlice.reducer,
  [ReducerName.User]: userSlice.reducer,
});

export const api = createApi();
export const loopApi = createLoopApi();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api, loopApi },
      },
    }).concat(redirect),
});
