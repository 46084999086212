import { createSlice } from "@reduxjs/toolkit";
import { ReducerName } from "../../../utils/enums";
import { TEmployeeState } from "../../../utils/types/state.types";
import { deleteEmployeeAction, fetchEmployeesAction } from "./employee.actions";

const employeeInitialState: TEmployeeState = {
  employees: undefined,
  isLoading: false,
  error: null,
};

export const employeeSlice = createSlice({
  name: ReducerName.Employee,
  initialState: employeeInitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEmployeesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployeesAction.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEmployeesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteEmployeeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEmployeeAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteEmployeeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});
