import React, { memo, useEffect, useState } from "react";
import { Input, Spin, theme } from "antd";
import useAppSelector from "../../hooks/use-app-selector/use-app-selector";
import {
  getEmployees,
  getEmployeesLoading,
} from "../../store/slices/employee/employee.selectors";
import EmplStatsElement from "../elements/empl-stats-element";
import { EmplTableElement } from "../elements/empl-table-element";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { fetchEmployeesAction } from "../../store/slices/employee/employee.actions";
import { TEmployee } from "../../utils/types/employee.types";
import { SearchProps } from "antd/es/input";
import { getUsers } from "../../store/slices/user/user.selectors";
import { fetchUsersAction } from "../../store/slices/user/user.actions";
import AddEmployeeModalBtnElement from "../elements/modal-buttons/add-employee-modal-btn-element";

const EmployeesPage = () => {
  const dispatch = useAppDispatch();

  const employees = useAppSelector(getEmployees);
  const isEmplsLoading = useAppSelector(getEmployeesLoading);
  const users = useAppSelector(getUsers);

  const [hr, setHr] = useState<string>();
  const [emplList, setEmplList] = useState<TEmployee[] | undefined>(employees);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (!employees) {
      dispatch(fetchEmployeesAction());
    }

    if (!users) {
      dispatch(fetchUsersAction());
    }

    if (hr) {
      setEmplList(
        employees?.filter(({ manager: { full_name } }) =>
          full_name.includes(hr)
        )
      );
    } else {
      setEmplList(employees);
    }
  }, [employees, hr, users, dispatch]);

  const onSearch: SearchProps["onSearch"] = (value) => {
    setHr(value);
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setHr(e.target.value);
  };

  return (
    <Spin spinning={isEmplsLoading}>
      <EmplStatsElement emplList={emplList} />
      <Input.Search
        size="large"
        variant="outlined"
        addonBefore="Фильтр по ФИО куратора"
        style={{
          marginBottom: "2vh",
          borderRadius: borderRadiusLG,
          background: colorBgContainer,
        }}
        onSearch={onSearch}
        onChange={onChange}
      />
      <EmplTableElement emplList={emplList}>
        <AddEmployeeModalBtnElement />
      </EmplTableElement>
    </Spin>
  );
};

export default memo(EmployeesPage);
