import { createSelector } from "@reduxjs/toolkit";
import { State } from "../../../utils/types/state.types";

export const getEmployeeState = (state: State) => state.EMPLOYEE;

export const getEmployees = createSelector(
  getEmployeeState,
  (state) => state.employees
);

export const getEmployeesLoading = createSelector(
  getEmployeeState,
  (state) => state.isLoading
);

export const getEmployeesError = createSelector(
  getEmployeeState,
  (state) => state.error
);
