import React, { memo, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import useCaps from "../../../hooks/use-caps/use-caps";
import { updateActionUrlAction } from "../../../store/slices/cap/cap.actions";
import { useUserData } from "../../../hooks/user-user-data/use-user-data";
import { Role } from "../../../utils/enums";

const EditActionUrlButtonElement = () => {
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { actionUrl } = useCaps();
  const { role } = useUserData();

  const urlEditDisabled = role !== Role.SuperAdmin;

  const onUpdateModalCancel = () => {
    form.resetFields();
    setIsEditModalOpen(false);
  };

  const onUpdateFormFinish = ({ url }: { url: string }) => {
    dispatch(updateActionUrlAction(url));
    setIsEditModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Input
        disabled
        size="large"
        value={actionUrl}
        addonBefore="Url для кнопок"
        addonAfter={
          <>
            <Button
              disabled={urlEditDisabled}
              variant="filled"
              color="primary"
              onClick={() => setIsEditModalOpen(true)}
              style={{
                //maxHeight: "2vh",
              }}
            >
              Редактировать
            </Button>
            <Modal
              title="Редактировать"
              open={isEditModalOpen}
              onOk={() => form.submit()}
              onCancel={onUpdateModalCancel}
              okText="Применить"
              cancelText="Не надо"
              okButtonProps={{ variant: "solid", color: "danger" }}
            >
              <Form
                form={form}
                onFinish={onUpdateFormFinish}
                initialValues={{
                  url: actionUrl,
                }}
              >
                <Form.Item label="Url" name="url">
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </>
        }
        style={{
          marginBottom: "2vh",
        }}
      />
    </>
  );
};

export default memo(EditActionUrlButtonElement);
