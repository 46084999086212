import { createAsyncThunk } from "@reduxjs/toolkit";
import AppDispatch from "../../../utils/types/app-dispatch.types";
import { State } from "../../../utils/types/state.types";
import { AxiosInstance } from "axios";
import {
  TUpdateUserPayload,
  TUpdateUserValues,
  TUser,
} from "../../../utils/types/user.types";
import { serializeAxiosError } from "../../../utils/utils";
import { Role } from "../../../utils/enums";

export const fetchUsersAction = createAsyncThunk<
  TUser[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("user/fetchUsers", async (_, { rejectWithValue, extra: { api } }) => {
  try {
    const { data } = await api.get<TUser[]>(`/user/findAll`);

    return data;
  } catch (err) {
    return rejectWithValue(
      serializeAxiosError(err, "Ошибка при запросе пользователей")
    );
  }
});

export const addUserAction = createAsyncThunk<
  TUser,
  { email: string; role?: Role },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "user/addUser",
  async (payload, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      const { data } = await api.post<TUser>(`/user/create`, payload);

      dispatch(fetchUsersAction());

      return data;
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при добавлении пользователя")
      );
    }
  }
);

export const updateUserAction = createAsyncThunk<
  TUser,
  TUpdateUserPayload,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "user/updateUser",
  async ({ email, role }, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      const { data } = await api.post<TUser>(`/user/update`, { email, role });

      dispatch(fetchUsersAction());

      return data;
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при удалении пользователя")
      );
    }
  }
);

export const deleteUserAction = createAsyncThunk<
  void,
  { email: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "user/deleteUser",
  async ({ email }, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      await api.post(`/user/delete`, { email });

      dispatch(fetchUsersAction());
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при удалении пользователя")
      );
    }
  }
);
