import React, { useEffect } from "react";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthPage } from "../pages/auth-page";
import MainPage from "../pages/main-page";
import useAppSelector from "../../hooks/use-app-selector/use-app-selector";
import { getAuthStatus } from "../../store/slices/auth/auth.selectors";
import AppHeaderElement from "../elements/app-header-element";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { setAuth } from "../../store/slices/auth/auth.slice";
import Employees from "../pages/employees-page";
import Caps from "../pages/caps-page";
import PrivateRoute from "../private-route";
import { Role } from "../../utils/enums";
import Users from "../pages/users-page";

const { Content, Footer } = Layout;

const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector(getAuthStatus);

  useEffect(() => {
    const authString = localStorage.getItem("user");

    if (authString) {
      dispatch(setAuth(authString));
    } else {
      if (status === "noAuth") {
        navigate("/");
      }
    }
  }, [status, navigate, dispatch]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppHeaderElement />
      <Content style={{ padding: "0 48px", alignItems: "center" }}>
        <Routes>
          <Route path="/">
            <Route index element={<MainPage></MainPage>} />
            <Route path="auth" element={<AuthPage />} />

            <Route
              path="employees"
              element={
                <PrivateRoute>
                  <Employees />
                </PrivateRoute>
              }
            />
            <Route
              path="caps"
              element={
                <PrivateRoute>
                  <Caps />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute forRoles={[Role.Admin, Role.SuperAdmin]}>
                  <Users />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Cделано в Отделе Внутреннего IT © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default App;
