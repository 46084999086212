import dayjs from "dayjs";
import {
  AdaptReaction,
  CapFormField,
  EmployeeStatus,
  LoopStyle,
} from "./enums";
import { TEmployee } from "./types/employee.types";
import { CascaderOption } from "./types/cascader-option.types";
import { TCap } from "./types/cap.types";
import { TAuthState } from "./types/state.types";
import { AxiosError } from "axios";
import { SerializedError } from "@reduxjs/toolkit";

/** Выбор иконки в зависимости от статуса */
export const getMainResultIcon = (status: TAuthState["status"]) => {
  switch (status) {
    case "noAuth":
      return `logo-hat.png`;
    case "auth":
      return `logo-cool.png`;
  }
};

/** Получение статуса сотрудника по его показателям */
export const getEmplStatus = ({ isFired, day, dayUpdatedAt }: TEmployee) => {
  switch (true) {
    case isFired:
      return EmployeeStatus.Fired;
    case day < 0:
      return EmployeeStatus.Done;
    case dayjs().diff(dayjs(dayUpdatedAt), "days") > 20:
      return EmployeeStatus.Bailed;
    case dayjs().businessDiff(dayjs(dayUpdatedAt)) > 1:
      return EmployeeStatus.Stuck;
    default:
      return EmployeeStatus.InProcess;
  }
};

/** Получение заголовка для блока кнопки */
export const getButtonTitle = (id: string) => {
  let res = "Кнопка ";
  switch (id) {
    case "nextCap":
      return (res += "след. капсулы");
    case "askHR":
      return (res += "вопроса HR");
    case AdaptReaction.Bad:
      return (res += 'реакции "Плохо"');
    case AdaptReaction.Great:
      return (res += 'реакции "Отлично"');
    case AdaptReaction.NotGood:
      return (res += 'реакции "Не очень"');
    case AdaptReaction.OK:
      return (res += 'реакции "Нормально"');
    default:
      return (res += "бонус-капсулы");
  }
};

/** Получение опций из списка капсул */
export const getCapOptions = (caps?: TCap[]) =>
  caps?.reduce((prev, curr) => {
    const res: CascaderOption[] = [...prev];

    if (curr.day || curr.bonusDay) {
      const dayName = `День ${curr.day?.index ?? curr.bonusDay?.index}`;

      if (!res.find(({ label }) => label === dayName)) {
        res.push({
          label: dayName,
          value: `day${curr.day?.index ?? curr.bonusDay?.index}`,
          children: [],
        });
      }

      const found = res.find(({ label }) => label === dayName);

      if (curr.day) {
        found?.children?.push({
          label: `Капсула ${curr.index}`,
          value: curr.name,
        });
      } else {
        found?.children?.push({
          label: `Бонус`,
          value: curr.name,
        });
      }
    } else {
      if (!res.find(({ value }) => value === "other")) {
        res.push({
          label: "Прочие",
          value: "other",
          children: [],
        });
      }

      const found = res.find(({ value }) => value === "other");

      found?.children?.push({
        label: curr.label,
        value: curr.name,
      });
    }
    return res;
  }, [] as CascaderOption[]);

/** Заполнение поста данными из формы редактирования капсулы */
export const getCapFormPost = (
  values: {
    [key in CapFormField]?: string;
  },
  capPost: TCap["post"]
) => {
  const post: TCap["post"] = JSON.parse(JSON.stringify(capPost));

  const attachment = post.props?.attachments?.[0];

  for (const k in values) {
    const key = k as CapFormField;

    const value = values[key];

    if (key === "capMessage" && value !== undefined) {
      post.message = value;
    }

    if (attachment) {
      if (key === "capMarkdown" && value !== undefined) {
        attachment.text = value;
      } else if (key === "capTitle" && value !== undefined) {
        attachment.title = value;
      } else if (value !== undefined) {
        const groups = key.match(
          /btn(?<id>[A-z_/d]*)(?<type>style|name|url)/
        )?.groups;

        const id = groups?.id;
        const type = groups?.type;

        const button = attachment.actions?.find((btn) => btn.id === id);

        if (button && id && type) {
          const btnType = type as "style" | "name" | "url";

          switch (btnType) {
            case "name":
              button.name = value;
              break;
            case "style":
              button.style = value as LoopStyle;
              break;
            case "url":
              button.integration.url = value;
              break;
          }
        }
      }
    }
  }

  return post;
};

/** Обработка ошибок */
export const serializeAxiosError = (
  e: unknown,
  prefix?: string
): SerializedError => {
  const error = e as AxiosError;

  return {
    name: error.code,
    message: `${prefix}: ${error.message}`,
    code: String(error.status),
    stack: error.stack,
  };
};
