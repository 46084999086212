import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Divider,
  Input,
  Spin,
  Table,
  TableProps,
  theme,
} from "antd";
import useAppSelector from "../../hooks/use-app-selector/use-app-selector";
import useAppDispatch from "../../hooks/use-app-dispatch/use-app-dispatch";
import { SearchProps } from "antd/es/input";
import {
  getUsers,
  getUsersLoading,
} from "../../store/slices/user/user.selectors";
import { fetchUsersAction } from "../../store/slices/user/user.actions";
import { TUser } from "../../utils/types/user.types";
import { useUserData } from "../../hooks/user-user-data/use-user-data";
import { Role } from "../../utils/enums";
import AddUserModalBtnElement from "../elements/modal-buttons/add-user-modal-btn-element";
import EditUserModalBtnElement from "../elements/modal-buttons/edit-user-modal-btn-element";
import DeleteUserModalBtnElement from "../elements/modal-buttons/delete-user-modal-btn-element";

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(getUsers);
  const isLoading = useAppSelector(getUsersLoading);

  const [searchName, setSearchName] = useState<string>();
  const [userList, setUserList] = useState<TUser[] | undefined>(users);

  const { role } = useUserData();

  const hasRoleField = role === Role.Admin || role === Role.SuperAdmin;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onNameSearch: SearchProps["onSearch"] = (value) => {
    setSearchName(value);
  };

  const onNameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchName(e.target.value);
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsersAction());
    }

    if (searchName) {
      setUserList(
        users?.filter(({ full_name }) => full_name.includes(searchName))
      );
    } else {
      setUserList(users);
    }
  }, [users, searchName, dispatch]);

  const columns: TableProps<TUser>["columns"] = [
    {
      title: "ФИО",
      dataIndex: "full_name",
      key: "userName",
      sorter: {
        compare: (a, b) => a.full_name.localeCompare(b.full_name),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "userEmail",
      sorter: {
        compare: (a, b) => a.full_name.localeCompare(b.full_name),
      },
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "userRole",
      sorter: {
        compare: (a, b) => a.role.localeCompare(b.role),
      },
    },
    {
      title: "Подопечных",
      dataIndex: "employees",
      key: "employeeCount",
      render(v) {
        return v.length;
      },
      sorter: {
        compare: (a, b) => a.employees.length - b.employees.length,
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "deleteButton",
      render(v, r) {
        return (
          r.role !== Role.SuperAdmin &&
          hasRoleField && (
            <>
              <EditUserModalBtnElement user={r} />
              <Divider type="vertical" />
              <DeleteUserModalBtnElement user={r} />
            </>
          )
        );
      },
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Card
        style={{
          marginBottom: "3vh",
          borderRadius: borderRadiusLG,
          background: colorBgContainer,
          marginTop: "3vh",
        }}
      >
        <AddUserModalBtnElement />
        <Divider />
        <Input.Search
          addonBefore="Фильтр по имени"
          onSearch={onNameSearch}
          onChange={onNameChange}
          style={{ minHeight: "6vh", marginBottom: "1vh" }}
        />
        <Table<TUser>
          columns={columns}
          dataSource={userList}
          rowKey={(obj) => obj.email}
        />
      </Card>
    </Spin>
  );
};

export default memo(UsersPage);
