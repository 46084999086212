import React, { memo, PropsWithChildren } from "react";
import { Card, Col, Row, Statistic, theme } from "antd";
import {
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseSquareOutlined,
  PauseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { TEmployee } from "../../utils/types/employee.types";
import { EmployeeStatus } from "../../utils/enums";

type EmplStatsProps = {
  children?: JSX.Element;
  emplList?: TEmployee[];
};

export const EmplStatsElement = ({
  emplList = [],
  children,
}: PropsWithChildren<EmplStatsProps>) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const isDoneCount = emplList?.filter(
    ({ status }) => status === EmployeeStatus.Done
  ).length;
  const inProcessCount = emplList?.filter(
    ({ status }) => status === EmployeeStatus.InProcess
  ).length;
  const isStuckCount = emplList?.filter(
    ({ status }) => status === EmployeeStatus.Stuck
  ).length;
  const hasBailedCount = emplList?.filter(
    ({ status }) => status === EmployeeStatus.Bailed
  ).length;
  const isFiredCount = emplList?.filter(
    ({ status }) => status === EmployeeStatus.Fired
  ).length;

  return (
    <Card
      title="Статистика"
      style={{
        marginBottom: "3vh",
        background: colorBgContainer,
        minHeight: 280,
        padding: 24,
        borderRadius: borderRadiusLG,
        marginTop: "3vh",
      }}
    >
      {children && children}
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title="Прошли адаптацию"
            value={isDoneCount}
            prefix={<CheckSquareOutlined />}
            suffix={`(${isDoneCount ? (100 / isDoneCount).toFixed(0) : 0}%)`}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="В процессе"
            prefix={<ClockCircleOutlined />}
            suffix={`(${inProcessCount ? (100 / inProcessCount).toFixed(0) : 0}%)`}
            value={inProcessCount}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Застряли"
            value={isStuckCount}
            prefix={<PauseCircleOutlined />}
            suffix={`(${isStuckCount ? (100 / isStuckCount).toFixed(0) : 0}%)`}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title="Забили"
            prefix={<WarningOutlined />}
            suffix={`(${hasBailedCount ? (100 / hasBailedCount).toFixed(0) : 0}%)`}
            value={hasBailedCount}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Уволены"
            value={isFiredCount}
            prefix={<CloseSquareOutlined />}
            suffix={`(${isFiredCount ? (100 / isFiredCount).toFixed(0) : 0}%)`}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default memo(EmplStatsElement);
