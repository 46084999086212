import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { setAuthAction } from "./auth.actions";
import { ReducerName } from "../../../utils/enums";
import { TAuthState } from "../../../utils/types/state.types";

const initialState: TAuthState = { status: "noAuth", isLoading: false };

export const authSlice = createSlice({
  name: ReducerName.Auth,
  initialState,
  reducers: {
    logOut: (state) => {
      state.status = "noAuth";
      localStorage.clear();
    },
    setAuth: (state, { payload }: PayloadAction<string>) => {
      state.status = "auth";
      state.data = JSON.parse(payload);
    },
    resetAuthError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(setAuthAction.pending, (state) => {
      state.isLoading = true;
      state.status = "noAuth";
      state.data = undefined;
      localStorage.clear();
    });
    builder.addCase(setAuthAction.fulfilled, (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload));

      state.status = "auth";
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(setAuthAction.rejected, (state, action) => {
      localStorage.clear();

      state.data = undefined;
      state.error = action.payload as SerializedError;
      state.status = action.error.code === "401" ? "noAccess" : "noAuth";
      state.isLoading = false;
    });
  },
});

export const { logOut, setAuth, resetAuthError } = authSlice.actions;
