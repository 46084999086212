import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TAddEmployeePayload,
  TEmployee,
  TUpdateEmployeePayload,
} from "../../../utils/types/employee.types";
import AppDispatch from "../../../utils/types/app-dispatch.types";
import { State } from "../../../utils/types/state.types";
import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "../../../utils/const";
import { getEmplStatus, serializeAxiosError } from "../../../utils/utils";

export const fetchEmployeesAction = createAsyncThunk<
  TEmployee[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("employee/fetchEmployees", async (_, { rejectWithValue, extra: { api } }) => {
  try {
    const { data } = await api.post<TEmployee[]>(`/employee/findAll`);

    for (const employee of data) {
      /** Проствляем статусы */
      employee.status = getEmplStatus(employee);

      /** Начинаем счет дней не с 0, а с 1 для удобствя */
      if (employee.day >= 0) {
        employee.day += 1;
      }

      /** То же самое с капсулами */
      employee.cap += 1;

      try {
        /** Проверяем доступность аватарки сотрудника */
        await axios.get(`${API_BASE_URL}/static/av/${employee.id}.png`);
      } catch (err) {
        console.warn(
          `Не получилось найти аватарку для пользователя ${employee.full_name} (${employee.id})`,
          err
        );
        /** Заменяем ссылки на пустые если аватарка недоступна */
        employee.avatar_url = "";
      }
    }

    /** Возвращаем список сотрудников */
    return data;
  } catch (err) {
    return rejectWithValue(
      serializeAxiosError(err, "Ошибка при запросе сотрудников")
    );
  }
});

export const addEmployeeAction = createAsyncThunk<
  TEmployee,
  TAddEmployeePayload,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "employee/addEmployee",
  async (dto, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      const { data } = await api.post<TEmployee>(`/employee/create`, dto);

      dispatch(fetchEmployeesAction());

      return data;
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при добавлении пользователя")
      );
    }
  }
);

export const updateEmployeeAction = createAsyncThunk<
  TEmployee,
  { id: string; dto: TUpdateEmployeePayload },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "employee/updateEmployee",
  async ({ id, dto }, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      const { data } = await api.post<TEmployee>(`/employee/update/${id}`, dto);

      dispatch(fetchEmployeesAction());

      return data;
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при добавлении пользователя")
      );
    }
  }
);

export const deleteEmployeeAction = createAsyncThunk<
  void,
  { id: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "employee/deleteEmployee",
  async ({ id }, { rejectWithValue, dispatch, extra: { api } }) => {
    try {
      await api.post(`employee/delete/${id}`);

      dispatch(fetchEmployeesAction);
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при удалении сотрудника")
      );
    }
  }
);
