import React, { memo, useState } from "react";
import { Button, Modal } from "antd";
import { TEmployee } from "../../../utils/types/employee.types";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { deleteEmployeeAction } from "../../../store/slices/employee/employee.actions";

const DeleteEmployeeModalBtnElement = ({
  employee,
}: {
  employee: TEmployee;
}) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onDeleteModalOk = (id: string) => {
    dispatch(deleteEmployeeAction({ id }));
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Button
        block
        variant="filled"
        color="danger"
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Удалить
      </Button>
      <Modal
        title="Подтверждение"
        open={isDeleteModalOpen}
        onOk={() => onDeleteModalOk(employee.id)}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        okText="Удалить"
        cancelText="Не надо"
        okButtonProps={{ variant: "solid", color: "danger" }}
      >
        Точно удалить сотрудника? Восстановить обратно не получится
      </Modal>
    </>
  );
};

export default memo(DeleteEmployeeModalBtnElement);
