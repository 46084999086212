import { PresetColorKey } from "antd/es/theme/internal";

export enum Role {
  SuperAdmin = "SUPER_ADMIN",
  Admin = "ADMIN",
  User = "USER",
}

export enum ReducerName {
  Auth = "AUTH",
  Cap = "CAP",
  Employee = "EMPLOYEE",
  User = "USER",
}

export enum EmployeeStatus {
  Fired = "Уволен(а)",
  InProcess = "В процессе",
  Stuck = "Застрял(а)",
  Bailed = "Забил(а)",
  Done = "Завершил(а)",
}

export enum AdaptReaction {
  OK = "OK",
  Great = "GREAT",
  NotGood = "NOT_GOOD",
  Bad = "BAD",
}

export const EmployeeStatusColor: Record<EmployeeStatus, PresetColorKey> = {
  "В процессе": "geekblue",
  "Забил(а)": "volcano",
  "Уволен(а)": "magenta",
  "Завершил(а)": "green",
  "Застрял(а)": "yellow",
};

export const AdaptReactionRu: Record<AdaptReaction, string> = {
  GREAT: `Всё отлично! 🔥`,
  OK: `Нормальный 😌`,
  NOT_GOOD: `Пока не очень хорошо 😢`,
  BAD: `Всё плохо, помогите 😱`,
};

export const AdaptReactionColor: Record<AdaptReaction, PresetColorKey> = {
  BAD: "volcano",
  GREAT: "green",
  OK: "blue",
  NOT_GOOD: "yellow",
};

export enum CapFormField {
  Title = "capTitle",
  Message = "capMessage",
  Markdown = "capMarkdown",
  NextCap = "nextCap",
  AskHr = "askHr",
  BonusCap = "bonusCap",
  ReactBad = "BAD",
  ReactOk = "OK",
  ReactGreat = "GREAT",
  ReactNotGood = "NOT_GOOD",
}

export enum LoopStyle {
  Good = "good",
  Warning = "warning",
  Danger = "danger",
  Default = "default",
  Primary = "primary",
  Success = "success",
}

export const LoopStyleAntColor: Record<LoopStyle, PresetColorKey | string> = {
  danger: "volcano",
  default: "gray",
  good: "green",
  primary: "blue",
  success: "green",
  warning: "yellow",
};
