import React, { memo, useState } from "react";
import { DatePicker, Form, Spin, Button, Modal, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useUserData } from "../../../hooks/user-user-data/use-user-data";
import { TAddEmployeeValues } from "../../../utils/types/employee.types";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { addEmployeeAction } from "../../../store/slices/employee/employee.actions";
import dayjs from "dayjs";
import LoopUserSelectElement from "../loop-user-select-element";
import { getUsersLoading } from "../../../store/slices/user/user.selectors";
import useAppSelector from "../../../hooks/use-app-selector/use-app-selector";
import { getEmployees } from "../../../store/slices/employee/employee.selectors";

const AddEmployeeModalBtnElement = () => {
  const dispatch = useAppDispatch();
  const employees = useAppSelector(getEmployees);

  const [form] = useForm();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const isUsersLoading = useAppSelector(getUsersLoading);

  const { managerId, authData } = useUserData();

  const onAddModalCancel = () => {
    form.resetFields();
    setIsAddModalOpen(false);
  };

  const onAddFormFinish = ({
    employeeId,
    adaptStart,
    probEnd,
  }: TAddEmployeeValues) => {
    dispatch(
      addEmployeeAction({
        managerId: managerId ?? "",
        employeeId,
        adaptationStartDate: adaptStart.toDate(),
        probationEndDate: probEnd.toDate(),
      })
    );
    setIsAddModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        block
        type="primary"
        style={{
          minHeight: "6vh",
          fontWeight: "bolder",
        }}
        onClick={() => setIsAddModalOpen(true)}
      >
        Добавить сотрудника
      </Button>
      <Modal
        title="Добавить пользователя"
        open={isAddModalOpen}
        onCancel={onAddModalCancel}
        onOk={() => form.submit()}
        okText="Добавить"
        cancelText="Не надо"
        cancelButtonProps={{
          variant: "filled",
          color: "danger",
        }}
        okButtonProps={{
          variant: "solid",
          color: "primary",
        }}
      >
        <Spin spinning={isUsersLoading}>
          <Form
            form={form}
            onFinish={onAddFormFinish}
            initialValues={{
              id: managerId,
              email: authData?.email,
              adaptStart: dayjs(),
              probEnd: dayjs().add(3, "month"),
            }}
          >
            <Form.Item label="Твой ID в Loop" name="id">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Твой email" name="email">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Выбери сотрудника"
              name="employeeId"
              required={true}
              rules={[
                {
                  required: true,
                  message: "Без выбора пользователя ничего не выйдет!",
                },
                {
                  warningOnly: true,
                  validator: async (_, value: string) =>
                    new Promise<void>((resolve, reject) => {
                      if (employees?.some(({ id }) => id === value)) {
                        reject("Такой пользователь уже существует, адаптация будет запущена заново");
                      } else {
                        resolve();
                      }
                    }),
                },
              ]}
            >
              <LoopUserSelectElement valueProp="id" />
            </Form.Item>
            <Form.Item label="Выбери дату начала адаптации" name="adaptStart">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Выбери дату окончания испыталки" name="probEnd">
              <DatePicker />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(AddEmployeeModalBtnElement);
