import React, { memo, ReactNode, useCallback, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { TCap } from "../../../utils/types/cap.types";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  theme,
} from "antd";
import { getButtonTitle, getCapFormPost } from "../../../utils/utils";
import { updateCapAction } from "../../../store/slices/cap/cap.actions";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { useForm } from "antd/es/form/Form";
import { LoopStyle, LoopStyleAntColor, Role } from "../../../utils/enums";
import { useUserData } from "../../../hooks/user-user-data/use-user-data";

const CapEditorModalBtnElement = ({ cap }: { cap: TCap }) => {
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const [isCapEditorOpen, setIsCapEditorOpen] = useState(false);

  const { role } = useUserData();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const capAttachment = cap?.post.props?.attachments?.[0];
  const urlDisabled = role !== Role.SuperAdmin;

  const styleRender = ({
    value,
    label,
  }: {
    value?: ReactNode;
    label?: ReactNode;
  }) => (
    <Tag
      color={LoopStyleAntColor[value as LoopStyle]}
      style={{ border: "0px", fontWeight: "bold" }}
    >
      {label}
    </Tag>
  );

  const onFinish = (values: Record<string, string>) => {
    dispatch(
      updateCapAction({
        name: cap.name,
        post: getCapFormPost(values, cap.post),
      })
    );
    setIsCapEditorOpen(false);
  };

  const onCancel = () => {
    setIsCapEditorOpen(false);
  };

  const getDefaultValues = useCallback(
    () => ({
      capTitle: capAttachment?.title,
      capMarkdown: capAttachment?.text,
      capMessage: cap?.post.message,
      ...capAttachment?.actions?.reduce(
        (prev, { name, id, style, integration }) => ({
          ...prev,
          [`btn${id}name`]: name,
          [`btn${id}style`]: style,
          [`btn${id}url`]: integration.url,
        }),
        {}
      ),
    }),
    [
      cap.post.message,
      capAttachment?.actions,
      capAttachment?.text,
      capAttachment?.title,
    ]
  );

  return (
    <>
      <Button
        variant="filled"
        color="primary"
        onClick={() => setIsCapEditorOpen(true)}
      >
        Редактировать
      </Button>
      <Modal
        title="Редактировать капсулу"
        open={isCapEditorOpen}
        onCancel={onCancel}
        onOk={() => form.submit()}
        okText="Применить"
        cancelText="Отмена"
        cancelButtonProps={{
          variant: "filled",
          color: "danger",
        }}
        okButtonProps={{
          variant: "solid",
          color: "primary",
        }}
        width="95vw"
      >
        <Form
          layout="horizontal"
          form={form}
          size="small"
          onFinish={onFinish}
          onReset={() => {
            form.setFieldsValue(getDefaultValues());
          }}
          initialValues={getDefaultValues()}
        >
          <Row gutter={16}>
            {capAttachment && (
              <Col span={12}>
                <Card
                  title="Заголовок блока"
                  style={{ marginBottom: "3vh", fontSize: 10 }}
                >
                  <Form.Item
                    name="capTitle"
                    help="Крупный шрифт в заголовке блока. Может быть пустым"
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Card>
              </Col>
            )}
            <Col span={12}>
              <Card title="Сообщение поста" style={{ marginBottom: "3vh" }}>
                <Form.Item
                  name="capMessage"
                  help="Идет перед основным блоком сообщения. Обычно пустое. Поддерживает markdown."
                >
                  <Input.TextArea rows={1} />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          {capAttachment && (
            <Card title="Текст блока" style={{ marginBottom: "3vh" }}>
              <Form.Item
                name="capMarkdown"
                help="Основной текст блока. Может быть пустым. Поддерживает markdown."
              >
                <MDEditor title="Текст блока" />
              </Form.Item>
            </Card>
          )}
          {!!capAttachment?.actions && (
            <Row gutter={16}>
              {capAttachment?.actions?.map(({ name, id }, i) => {
                return (
                  <Col
                    span={
                      capAttachment?.actions?.length &&
                      capAttachment?.actions?.length > 1
                        ? 12
                        : 24
                    }
                    key={`${name}-${i}`}
                  >
                    <Card
                      title={getButtonTitle(id)}
                      style={{
                        borderRadius: borderRadiusLG,
                        background: colorBgContainer,
                        marginBottom: "3vh",
                      }}
                    >
                      <Form.Item name={`btn${id}name`} label="Текст кнопки">
                        <Input />
                      </Form.Item>
                      <Form.Item name={`btn${id}style`} label="Цвет кнопки">
                        <Select
                          maxTagCount={1}
                          options={Object.values(LoopStyle).map((v) => ({
                            label: styleRender({ label: v, value: v }),
                            value: v,
                          }))}
                          size="middle"
                        />
                      </Form.Item>
                      <Form.Item
                        name={`btn${id}url`}
                        label="Url для отправки данных"
                      >
                        <Input disabled={urlDisabled} />
                      </Form.Item>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default memo(CapEditorModalBtnElement);
