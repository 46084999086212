import { createAsyncThunk } from "@reduxjs/toolkit";
import AppDispatch from "../../../utils/types/app-dispatch.types";
import { State } from "../../../utils/types/state.types";
import { AxiosInstance } from "axios";
import { TCap } from "../../../utils/types/cap.types";
import { serializeAxiosError } from "../../../utils/utils";

export const fetchCapsAction = createAsyncThunk<
  TCap[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("caps/fetchCaps", async (_, { rejectWithValue, extra: { api } }) => {
  try {
    const { data } = await api.post<TCap[]>(`/cap/findAll`);

    for (const cap of data) {
      cap.index += 1;

      if (cap.day) {
        cap.day.index += 1;
      }

      if (cap.bonusDay) {
        cap.bonusDay.index += 1;
      }
    }

    /** Возвращаем капсулы */
    return data;
  } catch (err) {
    return rejectWithValue(
      serializeAxiosError(err, "Ошибка при запросе капсул")
    );
  }
});

export const updateCapAction = createAsyncThunk<
  TCap,
  { name: string; post: TCap["post"] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("caps/updateCap", async (cap, { rejectWithValue, extra: { api } }) => {
  try {
    const { data } = await api.post<TCap>(`/cap/update/${cap.name}`, cap);

    return data;
  } catch (err) {
    return rejectWithValue(
      serializeAxiosError(err, "Ошибка при обновлении капсулы")
    );
  }
});

export const fetchActionUrlAction = createAsyncThunk<
  string,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>("caps/getBaseUrl", async (_, { rejectWithValue, extra: { api } }) => {
  try {
    const { data } = await api.get<string>(`/cap/actionUrl`);

    return data;
  } catch (err) {
    return rejectWithValue(
      serializeAxiosError(err, "Ошибка при обновлении капсулы")
    );
  }
});

export const updateActionUrlAction = createAsyncThunk<
  void,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "caps/updateBaseUrl",
  async (url, { dispatch, rejectWithValue, extra: { api } }) => {
    try {
      await api.post<TCap>(`/cap/updateActionUrl`, { url });

      dispatch(fetchCapsAction());
      dispatch(fetchActionUrlAction());
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при обновлении капсулы")
      );
    }
  }
);

export const testCapAction = createAsyncThunk<
  void,
  { email: string; capName: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: { api: AxiosInstance };
  }
>(
  "caps/testCap",
  async ({ email, capName }, { rejectWithValue, extra: { api } }) => {
    try {
      await api.post(`/cap/sendCap/${capName}/${email}`);
    } catch (err) {
      return rejectWithValue(
        serializeAxiosError(err, "Ошибка при отправке капсулы")
      );
    }
  }
);
