import { useEffect } from "react";
import {
  getCaps,
  getCapsActionUrl,
  getCapsLoading,
} from "../../store/slices/cap/cap.selectors";
import useAppSelector from "../use-app-selector/use-app-selector";
import { getCapOptions } from "../../utils/utils";
import useAppDispatch from "../use-app-dispatch/use-app-dispatch";
import {
  fetchActionUrlAction,
  fetchCapsAction,
} from "../../store/slices/cap/cap.actions";

const useCaps = () => {
  const dispatch = useAppDispatch();

  const caps = useAppSelector(getCaps);
  const actionUrl = useAppSelector(getCapsActionUrl);
  const capsLoading = useAppSelector(getCapsLoading);

  useEffect(() => {
    if (!caps) dispatch(fetchCapsAction());
    if (!actionUrl) dispatch(fetchActionUrlAction());
  }, [caps, dispatch, capsLoading]);

  return {
    caps,
    actionUrl,
    capsLoading,
    capOptions: getCapOptions(caps),
  };
};

export default useCaps;
