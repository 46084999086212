import React, { memo, useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { Role } from "../../../utils/enums";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { TUpdateUserValues, TUser } from "../../../utils/types/user.types";
import { updateUserAction } from "../../../store/slices/user/user.actions";

const EditUserModalBtnElement = ({ user }: { user: TUser }) => {
  const dispatch = useAppDispatch();

  const [updateForm] = useForm();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const roleOptions = [Role.Admin, Role.User].map((role) => ({
    value: role,
    label: role,
  }));

  const onUpdateModalCancel = () => {
    updateForm.resetFields();
    setIsUpdateModalOpen(false);
  };

  const onUpdateFormFinish = ({ role, email }: TUpdateUserValues) => {
    dispatch(updateUserAction({ email, role }));
    setIsUpdateModalOpen(false);
    updateForm.resetFields();
  };

  return (
    <>
      <Button
        variant="filled"
        color="primary"
        onClick={() => setIsUpdateModalOpen(true)}
      >
        Редактировать
      </Button>
      <Modal
        title="Редактировать"
        open={isUpdateModalOpen}
        onOk={() => updateForm.submit()}
        onCancel={onUpdateModalCancel}
        okText="Применить"
        cancelText="Не надо"
        okButtonProps={{ variant: "solid", color: "danger" }}
      >
        <Form
          form={updateForm}
          onFinish={onUpdateFormFinish}
          initialValues={user}
        >
          <Form.Item label="Имя" name="full_name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Роль" name="role">
            <Select options={roleOptions}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(EditUserModalBtnElement);
