import React, { memo, useState } from "react";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  TEmployee,
  TUpdateEmployeePayload,
  TUpdateEmployeeValues,
} from "../../../utils/types/employee.types";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { updateEmployeeAction } from "../../../store/slices/employee/employee.actions";
import dayjs from "dayjs";

const EditEmployeeModalBtnElement = ({ employee }: { employee: TEmployee }) => {
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const onUpdateModalCancel = () => {
    form.resetFields();
    setIsUpdateModalOpen(false);
  };

  const onUpdateFormFinish = ({
    id,
    cap,
    day,
    probationEndDate,
    dayUpdatedAt,
  }: TUpdateEmployeeValues) => {
    const dto: TUpdateEmployeePayload = {};

    /** Адаптируем для бека */
    if (cap) dto.cap = cap - 1;
    if (day) dto.day = day - 1;
    if (probationEndDate) dto.probationEndDate = probationEndDate.toDate();
    if (dayUpdatedAt) dto.dayUpdatedAt = dayUpdatedAt.toDate();

    dispatch(updateEmployeeAction({ id, dto }));
    setIsUpdateModalOpen(false);
  };

  return (
    <>
      <Button
        block
        variant="filled"
        color="primary"
        onClick={() => setIsUpdateModalOpen(true)}
        style={{ marginBottom: "1vh" }}
      >
        Редакт.
      </Button>
      <Modal
        title="Редактировать"
        open={isUpdateModalOpen}
        onOk={() => form.submit()}
        onCancel={onUpdateModalCancel}
        okText="Применить"
        cancelText="Не надо"
        okButtonProps={{ variant: "solid", color: "danger" }}
      >
        <Form
          form={form}
          onFinish={onUpdateFormFinish}
          initialValues={{
            id: employee.id,
            email: employee.email,
            day: employee.day,
            cap: employee.cap,
            probationEndDate: dayjs(employee.probationEndDate),
            dayUpdatedAt: dayjs(employee.dayUpdatedAt),
          }}
        >
          <Form.Item label="ID" name="id">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="День"
            name="day"
            help="Для завершения адаптации поставь 0"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Капсула"
            name="cap"
            help="Для сброса на начало дня поставь 0"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Дата конца испытательного срока"
            name="probationEndDate"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Время последнего завершенного дня"
            name="dayUpdatedAt"
          >
            <DatePicker showTime />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(EditEmployeeModalBtnElement);
