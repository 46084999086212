import React from "react";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Role } from "../utils/enums";
import { useUserData } from "../hooks/user-user-data/use-user-data";

type PrivateRouteProps = {
  children: JSX.Element;
  forRoles?: Role[];
};

const PrivateRoute = ({
  children,
  forRoles,
}: PropsWithChildren<PrivateRouteProps>) => {
  const { role, status } = useUserData();

  if (forRoles) {
    return role && forRoles?.includes(role) ? children : <Navigate to={`/`} />;
  }

  return status === "auth" ? children : <Navigate to={`/`} />;
};

export default React.memo(PrivateRoute);
