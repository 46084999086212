import { createSelector } from "@reduxjs/toolkit";
import { State } from "../../../utils/types/state.types";

export const getAuthState = (state: State) => state.AUTH;

export const getAuthData = createSelector(getAuthState, (state) => state.data);

export const getAuthRole = createSelector(
  getAuthState,
  (state) => state.data?.role
);

export const getAuthLoopId = createSelector(
  getAuthState,
  (state) => state.data?.loopId
);

export const getAuthError = createSelector(
  getAuthState,
  (state) => state.error
);

export const getAuthStatus = createSelector(
  getAuthState,
  (state) => state.status
);

export const getAuthIsLoading = createSelector(
  getAuthState,
  (state) => state.isLoading
);
