import { createSelector } from "@reduxjs/toolkit";
import { State } from "../../../utils/types/state.types";

export const getCapsState = (state: State) => state.CAP

export const getCaps = createSelector(
  getCapsState,
  (state) => state.caps
);

export const getCapsActionUrl = createSelector(
  getCapsState,
  (state) => state.actionUrl
);

export const getCapsLoading = createSelector(
  getCapsState,
  (state) => state.isLoading
);

export const getCapsError = createSelector(
  getCapsState,
  (state) => state.error
);
