import { Button, Form, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { memo, useState } from "react";
import { Role } from "../../../utils/enums";
import useAppDispatch from "../../../hooks/use-app-dispatch/use-app-dispatch";
import { addUserAction } from "../../../store/slices/user/user.actions";
import { EMAIL_REGEX } from "../../../utils/const";
import LoopUserSelectElement from "../loop-user-select-element";
import { useUserData } from "../../../hooks/user-user-data/use-user-data";
import useAppSelector from "../../../hooks/use-app-selector/use-app-selector";
import { getUsers } from "../../../store/slices/user/user.selectors";

const AddUserModalBtnElement = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(getUsers);

  const [form] = useForm();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { role } = useUserData();

  const hasRoleField = role === Role.Admin || role === Role.SuperAdmin;
  const roleOptions = [Role.Admin, Role.User].map((role) => ({
    value: role,
    label: role,
  }));

  const onAddModalCancel = () => {
    form.resetFields();
    setIsAddModalOpen(false);
  };

  const onAddFormFinish = ({
    email,
    role = Role.User,
  }: {
    email: string;
    role?: Role;
  }) => {
    form.validateFields();
    dispatch(addUserAction({ email, role }));
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        block
        style={{
          minHeight: "6vh",
          fontWeight: "bolder",
        }}
        onClick={() => setIsAddModalOpen(true)}
      >
        Добавить пользователя
      </Button>
      <Modal
        title="Добавить пользователя"
        open={isAddModalOpen}
        onCancel={onAddModalCancel}
        onOk={() => form.submit()}
        okText="Добавить"
        cancelText="Не надо"
        cancelButtonProps={{
          variant: "filled",
          color: "danger",
        }}
        okButtonProps={{
          variant: "solid",
          color: "primary",
        }}
      >
        <Form
          form={form}
          onFinish={onAddFormFinish}
          initialValues={{
            role: Role.User,
          }}
        >
          <Form.Item
            label="Выбери пользователя в Loop"
            name="email"
            required={true}
            rules={[
              {
                required: true,
                message: "Без выбора пользователя ничего не выйдет!",
              },
              {
                pattern: EMAIL_REGEX,
                message:
                  "Нужен рабочий email на домене foxford.ru или foxford.pro",
              },
              {
                validator: async (_, value: string) =>
                  new Promise<void>((resolve, reject) => {
                    if (users?.some(({ email }) => email === value)) {
                      reject("Такой пользователь уже существует!");
                    } else {
                      resolve();
                    }
                  }),
                message: "Такой пользователь уже существует!",
              },
            ]}
          >
            <LoopUserSelectElement valueProp="email" />
          </Form.Item>
          {hasRoleField && (
            <Form.Item label="Роль пользователя" name="role">
              <Select options={roleOptions} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default memo(AddUserModalBtnElement);
