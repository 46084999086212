import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const createLoopApi = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_LOOP_URL}/api/v4`,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_LOOP_TOKEN}`,
      Accept: "*/*",
      "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response) {
        const { data } = error.response;
        toast.error(
          data &&
            typeof data === "object" &&
            "error" in data &&
            typeof error === "object"
            ? (data.error as string)
            : error.message
        );
      }

      throw error;
    }
  );

  return api;
};
